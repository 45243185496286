import * as React from 'react'

import Layout from '../../../templates/layout'
import Seo from '../../../helpers/seo'
import CTA from '../../../components/organisms/CTA'
import CasesRelational from '../../../components/organisms/CasesRelational'
import formulaJson from './formula.json'
import Content from './content'
import Banner from '../../../components/molecules/Banner'

const Formula = () => {
  const { relational } = formulaJson

  return (
    <Layout page="Formula">
      <Banner image="banner-formula.webp" />
      <Content />
      <CTA />
      <CasesRelational relationalData={relational} itsRelationalCase />
    </Layout>
  )
}
export const Head = () => <Seo title="Fórmula 1" />

export default Formula
